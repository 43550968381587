import { gql } from '@apollo/client'

const STATION_PERFORMANCE_METRICS_ATTRIBUTES = gql`
  fragment StationPerformanceMetricsAttributes on StationMetric {
    value
    comparativeValue
    trend
  }
`

const STATION_PERFORMANCE_QUERY = gql`
  query stationPerformanceQuery(
    $stationId: ID!
    $supportedPricingTypes: [PricingType!]!
    $includeTotal: Boolean
  ) {
    station(id: $stationId) {
      id
      name
      hasBunkered
      targetMargin {
        id
        target
      }
      fuelGrades(includeTotal: $includeTotal) {
        fuelGrade {
          id
          shortTitle
          title
          primary
          secondary
          blended
        }
      }
      metrics(pricingTypes: $supportedPricingTypes) {
        pricingType
        fuelGradeId
        retailVolume {
          today {
            ...StationPerformanceMetricsAttributes
          }
          transactionCount {
            ...StationPerformanceMetricsAttributes
          }
          averageFillUp {
            ...StationPerformanceMetricsAttributes
          }
          fuelCardVolume {
            ...StationPerformanceMetricsAttributes
          }
          bunkeredVolume {
            ...StationPerformanceMetricsAttributes
          }
          payment {
            key
            value
            name
          }
        }
        grossMargin {
          today {
            ...StationPerformanceMetricsAttributes
          }
          yesterday {
            ...StationPerformanceMetricsAttributes
          }
          sevenDayAverage {
            ...StationPerformanceMetricsAttributes
          }
          bunkerCommission {
            ...StationPerformanceMetricsAttributes
          }
        }
        netMargin {
          today {
            ...StationPerformanceMetricsAttributes
          }
          yesterday {
            ...StationPerformanceMetricsAttributes
          }
          sevenDayAverage {
            ...StationPerformanceMetricsAttributes
          }
          bunkerCommission {
            ...StationPerformanceMetricsAttributes
          }
        }
        grossProfit {
          today {
            ...StationPerformanceMetricsAttributes
          }
          yesterday {
            ...StationPerformanceMetricsAttributes
          }
          monthToDate {
            ...StationPerformanceMetricsAttributes
          }
          bunkerCommission {
            ...StationPerformanceMetricsAttributes
          }
        }
        netProfit {
          today {
            ...StationPerformanceMetricsAttributes
          }
          yesterday {
            ...StationPerformanceMetricsAttributes
          }
          monthToDate {
            ...StationPerformanceMetricsAttributes
          }
          bunkerCommission {
            ...StationPerformanceMetricsAttributes
          }
        }
        businessProfit {
          today {
            ...StationPerformanceMetricsAttributes
          }
          yesterday {
            ...StationPerformanceMetricsAttributes
          }
          monthToDate {
            ...StationPerformanceMetricsAttributes
          }
          bunkerCommission {
            ...StationPerformanceMetricsAttributes
          }
        }
      }
    }
  }
  ${STATION_PERFORMANCE_METRICS_ATTRIBUTES}
`

export { STATION_PERFORMANCE_QUERY }
