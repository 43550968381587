import React, { useState, useEffect } from 'react'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'config/context/authContext'
import styles from './Login.module.scss'
import { EmailRegex } from 'utils/constants'
import { AuthDialog } from '../components/auth-dialog'

const cx = classNames.bind(styles)

const Login = () => {
  const { t } = useTranslation('auth')
  const { login } = useAuth()
  const [email, setEmail] = useState('')
  const [isEmailFocused, setEmailFocused] = useState(false)
  const [password, setPassword] = useState('')
  const [isPasswordFocused, setPasswordFocused] = useState(false)
  const [isFormInvalid, setFormInvalid] = useState(false)
  const [isEmailInvalid, setEmailInvalid] = useState(null)
  const [isPasswordInvalid, setPasswordInvalid] = useState(null)

  const handleSubmit = async () => {
    try {
      await login({ email, password })
    } catch (err) {
      setFormInvalid(true)
      setEmailInvalid(true)
      setPasswordInvalid(true)
    }
  }

  useEffect(() => {
    const token = window.location.hash.substring(1).trim()

    if (token) {
      // either we do this or we get an ugly semi-colon from prettier
      // eslint-disable-next-line no-unused-vars
      const promise = (async () => {
        try {
          await login({ token })
        } catch (err) {
          setFormInvalid(true)
        }
      })()
    }
  }, [login])

  const validateEmail = email => {
    const valid = EmailRegex.test(email)
    setEmailInvalid(email.length === 0 ? null : !valid)
    setPasswordInvalid(password.length === 0 ? null : false)
  }

  const handleChangeEmail = event => {
    const email = event.target.value.trim()
    // fix bug with focus/blur and webkit's handling of autofill
    setEmailFocused(true)
    validateEmail(email)
    setEmail(email)
  }

  const handleChangePassword = event => {
    const password = event.target.value

    // fix bug with focus/blur and webkit's handling of autofill
    setPasswordFocused(true)
    validateEmail(email)

    setPasswordInvalid(password.length === 0 ? null : false)
    setPassword(password)
  }

  const handleEmailFocus = () => setEmailFocused(true)
  const handleEmailBlur = () => setEmailFocused(false)

  const handlePasswordFocus = () => setPasswordFocused(true)
  const handlePasswordBlur = () => setPasswordFocused(false)

  return (
    <AuthDialog
      title={t('loginToEdgePetrol')}
      onSubmit={handleSubmit}
      link="/forgot-password"
      linkText={t('forgot')}
      buttonText={t('login')}
      error={isFormInvalid ? t('pleaseCheckYourEmailAndPassword') : null}
    >
      <div
        className={cx('input', {
          focused: isEmailFocused,
          invalid: !isEmailFocused && isEmailInvalid === true,
          valid: !isEmailFocused && isEmailInvalid === false,
        })}
      >
        <input
          type="email"
          placeholder={t('emailAddress')}
          name="email"
          data-testid="email"
          onChange={handleChangeEmail}
          onFocus={handleEmailFocus}
          onBlur={handleEmailBlur}
          value={email}
        />
      </div>
      <div
        className={cx('input', {
          focused: isPasswordFocused,
          invalid: !isPasswordFocused && isPasswordInvalid === true,
          valid: !isPasswordFocused && isPasswordInvalid === false,
        })}
      >
        <input
          type="password"
          placeholder={t('password')}
          name="password"
          data-testid="password"
          onChange={handleChangePassword}
          onFocus={handlePasswordFocus}
          onBlur={handlePasswordBlur}
          value={password}
        />
      </div>
    </AuthDialog>
  )
}

export { Login }
