import React, { createContext, useContext } from 'react'
import { useApolloClient, useQuery, useMutation } from '@apollo/client'
import { useLocation, useHistory } from 'react-router-dom'
import { resetCache } from 'config/apollo/cache'
import { useSentry } from 'components/common/hooks/useSentry'
import { SettingThingsUp } from 'components/main/components/setting-things-up'
import { useIntercom } from 'react-use-intercom'
import {
  AUTH_CONTEXT_QUERY,
  ACCEPT_TOS_MUTATION,
  SET_INITIAL_PASSWORD_MUTATION,
} from './AuthQueries'

const { REACT_APP_GATEWAY_URI } = process.env

export const AuthContext = createContext()

const AuthProvider = props => {
  const client = useApolloClient()
  const location = useLocation()
  const history = useHistory()

  const { loading, data, refetch } = useQuery(AUTH_CONTEXT_QUERY)
  const [_acceptTermsOfService] = useMutation(ACCEPT_TOS_MUTATION)
  const [_setInitialPassword] = useMutation(SET_INITIAL_PASSWORD_MUTATION)
  const { sentryLogout } = useSentry(data)
  const { shutdown } = useIntercom()

  if (loading && !data) {
    return <SettingThingsUp />
  }

  const login = async ({ token, email, password }) => {
    const action = `${REACT_APP_GATEWAY_URI}/auth/login`
    const fields = {
      token,
      email,
      password,
    }
    const response = await fetch(action, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(fields),
    })

    if (response.ok) {
      const { error } = await response.json()

      /* istanbul ignore next */
      if (error) {
        throw new Error('Invalid email and password combination')
      }

      await refetch()
      history.replace('/')
    } else {
      throw new Error('Unknown request error')
    }
  }

  const logout = async () => {
    const action = `${REACT_APP_GATEWAY_URI}/auth/logout`
    const response = await fetch(action, {
      method: 'POST',
      credentials: 'include',
    })

    if (response.ok) {
      const { error } = await response.json()

      /* istanbul ignore next */
      if (error) {
        throw new Error('Failed to logout')
      }

      sentryLogout()
      shutdown()
      await resetCache(client)
      history.replace('/login', { from: location })
      window.location.reload()
    } else {
      throw new Error('Unknown request error')
    }
  }

  const forgotPassword = async email => {
    const action = `${REACT_APP_GATEWAY_URI}/auth/forgot-password`
    const response = await fetch(action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    })

    if (response.ok) {
      const { error } = await response.json()

      /* istanbul ignore next */
      if (error) {
        throw new Error('Failed to send link by email')
      }
    } else {
      throw new Error('Unknown request error')
    }
  }

  const resetPassword = async password => {
    const action = `${REACT_APP_GATEWAY_URI}/auth/reset-password`
    const response = await fetch(action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${window.location.hash.replace(/^#/, '')}`,
      },
      body: JSON.stringify({
        password,
      }),
    })

    if (response.ok) {
      const { error } = await response.json()

      /* istanbul ignore next */
      if (error) {
        throw new Error('Failed to reset password')
      }

      history.replace('/login')
    } else {
      throw new Error('Unknown request error')
    }
  }

  const loggedIn = !!(data && data.me)

  const isNotificationUser = data?.me?.isNotificationUser
  const isEdgeDataProUser = data?.me?.company?.edgeDataPro

  const hasPassword = data?.me?.hasPassword
  const hasFinishedAccountSetup = !!data?.me?.hasFinishedAccountSetup
  const userLocale = data?.me.locale

  const termsOfService = data?.me?.termsOfService
  const termsOfServiceVersion = data?.me?.termsOfServiceVersion
  const name = {
    first: data?.me?.firstName,
    last: data?.me?.lastName,
  }
  const companyName = data?.me?.company?.name

  const acceptTermsOfService = async () => {
    const { from } = location.state || { from: { pathname: '/stations' } }

    const { data, error } = await _acceptTermsOfService({
      variables: {
        version: termsOfServiceVersion,
      },
    })

    /* istanbul ignore next */
    if (error) {
      throw error
    }

    if (data?.acceptTermsOfService?.error) {
      throw new Error(`Unknown error occurred accepting terms of service`)
    }

    await refetch()
    history.replace(from)
  }

  const setInitialPassword = async ({
    mobileNumber,
    password,
    finishedAccountSetupAt,
  }) => {
    const { from } = location.state || { from: { pathname: '/stations' } }

    const { data, error } = await _setInitialPassword({
      variables: {
        mobileNumber,
        password,
        finishedAccountSetupAt,
      },
    })

    /* istanbul ignore next */
    if (error) {
      throw error
    }

    if (data?.setInitialPassword?.error) {
      throw new Error(`Unknown error occurred setting initial password`)
    }

    await refetch()
    history.replace(from)
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        forgotPassword,
        resetPassword,
        loggedIn,
        isNotificationUser,
        isEdgeDataProUser,
        hasPassword,
        hasFinishedAccountSetup,
        userLocale,
        setInitialPassword,
        termsOfService,
        termsOfServiceVersion,
        acceptTermsOfService,
        name,
        companyName,
      }}
      {...props}
    />
  )
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
