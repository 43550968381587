import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetFallback } from 'components/common/widget-fallback'
import { withEdgeErrorBoundary } from 'components/common/with-edge-error-boundary'
import { useQuery, useReactiveVar } from '@apollo/client'
import { upperFirstOnly, getMetricTypeFromField, yAxisLabel } from 'utils/format'
import { useFuelGrades } from 'components/common/hooks/useFuelGrades'
import { useErrorHandler } from 'react-error-boundary'
import { useStations } from 'components/common/hooks/useStations'
import { createDateRangeFromNow, dateToString } from 'utils/dateTime'
import { useCompanyTimeZone } from 'components/common/hooks/useCompanyTimeZone'
import { Metrics } from './components/metrics'
import { Filters } from './components/filters'
import { PeriodAnalysisChart } from './components/period-analysis-chart'
import { PortfolioBenchmarkChart } from './components/portfolio-benchmark-chart'
import { CompetitorAnalysisChart } from './components/competitor-analysis-chart'
import { TabNavs } from './components/tab-navs'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useAuth } from 'config/context/authContext'
import { showRegionsDropdown } from 'utils/helpers'
import { PERIOD_ANALYSIS_QUERY } from './PeriodAnalysisQueries'
import { PORTFOLIO_BENCHMARK_QUERY } from './PortfolioBenchmarkQueries'
import { COMPETITOR_ANALYSIS_QUERY } from './CompetitorAnalysisQueries'
import { PeriodAnalysis, CompetitorAnalysis, PortfolioBenchmark } from 'utils/constants'
import { ExportButton } from './components/export-button'
import { DataTable } from './../performance-report/components/data-table'
import { useFormatMetricValue, useMetricSymbol } from 'components/common/hooks/useFormatMetricValue'
import { fuelGradeTitlesToIds } from 'utils/helpers'
import {
  stationsVar,
  fuelGradesVar,
  timeResolutionVar,
  currentPeriodDateVar,
  previousPeriodDateVar,
  groupByVar,
  activeTabVar,
  metricVar,
  regionsVar
} from 'config/apollo/analytics-report/variables'
import classNames from 'classnames/bind'
import styles from './AnalyticsReport.module.scss'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'

const cx = classNames.bind(styles)

const AnalyticsReport = ({ mainData }) => {
  const { companyTimeZone } = useCompanyTimeZone()
  const { showRegionDropdown } = useFlags()
  const { userLocale } = useAuth()
  const regionsDropdownFlag = showRegionsDropdown(showRegionDropdown, userLocale)
  const isEdgeDataPro = mainData?.me.company.edgeDataPro

  const activeTabFilter = useReactiveVar(activeTabVar)
  const [activeTab, setActiveTab] = useState(activeTabVar)

  const stationsFilter = useReactiveVar(stationsVar)
  const stations = useStations()
  const [stationIds, setStationIds] = useState(stationsVar)

  const timeResolutionFilter = useReactiveVar(timeResolutionVar)
  const groupByFilter = useReactiveVar(groupByVar)
  const currentPeriodDateFilter = useReactiveVar(currentPeriodDateVar)
  const previousPeriodDateFilter = useReactiveVar(previousPeriodDateVar)
  const metricFilter = useReactiveVar(metricVar)
  const benchmarkRegionFilter = useReactiveVar(regionsVar)

  const fuelGradesFilter = useReactiveVar(fuelGradesVar)
  const { fuelGrades: allFuelGrades, fuelGradesLoading } = useFuelGrades(stationIds[activeTab], null, isEdgeDataPro)
  const [fuelGrades, setFuelGrades] = useState(fuelGradesVar)

  function snakeToCamel(str) {
    return str.replace(/([-_]\w)/g, (matches) =>
      matches[1].toUpperCase()
    )
  }

  const metricType = getMetricTypeFromField(snakeToCamel(metricFilter[activeTab]))

  const setSelectedActiveTab = (tab) => {
    activeTabVar(tab)
    setActiveTab(tab)
  }

  useEffect(() => {
    if (isEdgeDataPro) {
      setSelectedActiveTab('PORTFOLIO_BENCHMARK');
    }
  }, [isEdgeDataPro, setSelectedActiveTab])

  useEffect(() => {
    if (!stationIds[activeTab].length) {
      const stationIds = stations.map(station => station.id)

      setStationIds((prevState) => ({
        ...prevState,
        [PortfolioBenchmark]: stationIds,
        [PeriodAnalysis]: stationIds.slice(0, 1),
        [CompetitorAnalysis]: stationIds,
      }))

      stationsVar({
        ...stationsVar(),
        [PortfolioBenchmark]: stationIds,
        [PeriodAnalysis]: stationIds.slice(0, 1),
        [CompetitorAnalysis]: stationIds,
      })
    } else {
      setSelectedStation(stationIds[activeTab])

    }
  }, [stations])

  useEffect(() => {
    if (!fuelGradesLoading && allFuelGrades && allFuelGrades.length !== 0) {
      const selectedGrades = fuelGrades[activeTab].length ? fuelGrades[activeTab] : allFuelGrades.map(fg => fg.title)
      setSelectedGrades(selectedGrades)
    }
  }, [fuelGrades[activeTab], activeTab, fuelGradesLoading])
  
  const setSelectedStation = (stationIds) => {
    setStationIds((prevState) => ({
      ...prevState,
      [activeTab]: stationIds
    }))
    stationsVar({
      ...stationsVar(),
      [activeTab]: stationIds
    })
  }

  const setSelectedGrades = (grades) => {
    const validGrades = grades.filter(grade => allFuelGrades.map(fg => fg.title).includes(grade))

    setFuelGrades((prevState) => ({
      ...prevState,
      [activeTab]: grades
    }))
    fuelGradesVar({
      ...fuelGradesVar(),
      [activeTab]: validGrades
    })
  }

  const { formatMetricValue } = useFormatMetricValue()
  const { formatMetricSymbol } = useMetricSymbol()

  const useCustomQuery = (queryType) => {
    let query

    switch (queryType) {
      case PeriodAnalysis:
        query = PERIOD_ANALYSIS_QUERY
        break
      case CompetitorAnalysis:
        query = COMPETITOR_ANALYSIS_QUERY
        break
      default:
        query = PORTFOLIO_BENCHMARK_QUERY
    }

    const { error, data, loading } = useQuery(query, {
      variables: {
        stationIds: stationsFilter[activeTab],
        fuelGradeIds: fuelGradeTitlesToIds(fuelGradesFilter[activeTab], allFuelGrades),
        currentPeriodStartDate: dateToString(
          companyTimeZone,
          currentPeriodDateFilter[activeTab]?.from ||
            createDateRangeFromNow(-7, -1, companyTimeZone).from
        ),
        currentPeriodEndDate: dateToString(
          companyTimeZone,
          currentPeriodDateFilter[activeTab]?.to || createDateRangeFromNow(-7, -1, companyTimeZone).to
        ),
        previousPeriodStartDate: dateToString(
          companyTimeZone,
          previousPeriodDateFilter[activeTab]?.from ||
            createDateRangeFromNow(-14, -8, companyTimeZone).from
        ),
        previousPeriodEndDate: dateToString(
          companyTimeZone,
          previousPeriodDateFilter[activeTab]?.to || createDateRangeFromNow(-14, -8, companyTimeZone).to
        ),
        timeResolution: timeResolutionFilter[activeTab].toLowerCase(),
        stationGroupNames: groupByFilter[activeTab],
        activeTab: activeTabFilter,
        metricName: metricFilter[activeTab],
        ...(regionsDropdownFlag && { benchmarkRegions: benchmarkRegionFilter.length ? benchmarkRegionFilter : '' }),
      },
      skip:
        !fuelGradesFilter[activeTab]?.length ||
        activeTab !== queryType,
      })
  
    useErrorHandler(error)
  
    return { data, loading }
  }
  
  const {
    data: periodAnalysisData,
    loading: loadingPeriodAnalysisData,
  } = useCustomQuery(PeriodAnalysis)
  
  const {
    data: portfolioBenchmarkData,
    loading: loadingPortfolioBenchmarkData,
  } = useCustomQuery(PortfolioBenchmark)

  const {
    data: competitorAnalysisData,
    loading: loadingCompetitorAnalysisData,
  } = useCustomQuery(CompetitorAnalysis)

  const renderChart = () => {
    const renderSpinner = () => (
      <div className={cx('pro-widget-spinner')}>
        <Spinner animation="grow" variant="primary" />
      </div>
    );
  
    const renderChartComponent = (Component, data, additionalProps = {}) => (
      <Component
        data={data}
        metricType={metricType}
        metricName={metricFilter[activeTab]}
        timeResolution={timeResolutionFilter[activeTab]}
        {...additionalProps}
      />
    );
  
    if ((activeTab === PortfolioBenchmark && loadingPortfolioBenchmarkData) ||
        (activeTab === PeriodAnalysis && loadingPeriodAnalysisData) ||
        (activeTab === CompetitorAnalysis && loadingCompetitorAnalysisData)) {
      return renderSpinner();
    }
  
    switch (activeTab) {
      case PortfolioBenchmark:
        return renderChartComponent(PortfolioBenchmarkChart, portfolioBenchmarkData, { isEdgeDataPro });
      case PeriodAnalysis:
        return renderChartComponent(PeriodAnalysisChart, periodAnalysisData);
      case CompetitorAnalysis:
        return renderChartComponent(CompetitorAnalysisChart, competitorAnalysisData);
      default:
        return null;
    }
  }

  const renderExportButton = () => {
    const formatValue = (value) => {
      return value == null ? '' : formatMetricValue(metricType, value, false);
    }
    const useMetricSymbol = formatMetricSymbol(metricType)
    
    const portfolioBenchmarkFormattedData = () => {
      const data = portfolioBenchmarkData?.portfolioBenchmark
      const formattedData = []
  
      for (let i = 0; i < data?.dates.length; i++) {
        const rowData = {
          'Date': moment.utc(data.dates[i]).format('Do MMM yyyy'),
          ...(isEdgeDataPro ? {} : {[`Your portfolio ${useMetricSymbol}`]: formatValue(data.yourPortfolio[i])}),
          [`Average edgepetrol site ${useMetricSymbol}`]: formatValue(data.averageEdgepetrolCompany[i]),
        }
  
        formattedData.push(rowData)
      }
  
      return formattedData
    }

    const competitorAnalysisFormattedData = () => {
      const data = competitorAnalysisData?.competitorAnalysis
      const formattedData = []

      for (let i = 0; i < data?.dates.length; i++) {
        const rowData = {
          'Date': moment.utc(data.dates[i]).format('Do MMM yyyy'),
          [`Your average pole price ${useMetricSymbol}`]: formatValue(data.yourAveragePolePrice[i]),
          [`Average competitor pole price ${useMetricSymbol}`]: formatValue(data.averageCompetitorPolePrice[i]),
          [`Min competitor pole price ${useMetricSymbol}`]: formatValue(data.minCompetitorPolePrice[i]),
          [`Max competitor pole price ${useMetricSymbol}`]: formatValue(data.maxCompetitorPolePrice[i]),
        }
  
        formattedData.push(rowData)
      }
  
      return formattedData
    }
  
    const periodAnalysisFormattedData = (periodType) => {
      const formattedData = []
      const allPeriodSites = periodAnalysisData?.periodAnalysis.data
      let periodSites = []
      let dates = []
      if (periodType === 'current') {
        periodSites = allPeriodSites?.slice(0, allPeriodSites.length / 2)
        dates = periodAnalysisData?.periodAnalysis.datesCurrentPeriod
      } else if (periodType === 'previous') {
        periodSites = allPeriodSites?.slice(allPeriodSites.length / 2, allPeriodSites.length)
        dates = periodAnalysisData?.periodAnalysis.datesPreviousPeriod
      }
    
      for (let i = 0; i < dates?.length; i++) {
        const rowData = {
          'Date': moment.utc(dates[i]).format('Do MMM yyyy'),
        }
    
        for (let j = 0; j < periodSites.length; j++) {
          const label = `${periodSites[j].label} ${useMetricSymbol}`;
          const value = periodSites[j].values[i]
          rowData[label] = formatValue(value)
        }
    
        formattedData.push(rowData)
      }
      return formattedData
    }

    const filenameDate = (period) => {
      const from = moment.utc(period[activeTab].from).format('DD/MM/yyyy')
      const to = moment.utc(period[activeTab].to).format('DD/MM/yyyy')
      const metric = activeTab === CompetitorAnalysis ? '' : yAxisLabel(metricFilter[activeTab])

      return (isEdgeDataPro ? '' : activeTab + '_') + metric + '_' + from + '-' + to
    }

    if (activeTab === PortfolioBenchmark) {
      return <ExportButton
              data={portfolioBenchmarkFormattedData()}
              filename={filenameDate(currentPeriodDateFilter)}
              buttonName={isEdgeDataPro ? 'Export data' : 'Export portfolio data'}
             />
    } else if (activeTab === CompetitorAnalysis) {
      return <ExportButton
              data={competitorAnalysisFormattedData()}
              filename={filenameDate(currentPeriodDateFilter)}
              buttonName={'Export competitor price'}
             />
    } else if (activeTab === PeriodAnalysis) {
      return (
        <div className={cx('export-buttons')}>
          <ExportButton
            data={periodAnalysisFormattedData('current')}
            filename={filenameDate(currentPeriodDateFilter)}
            buttonName={'Export current period'}
          />
          <ExportButton
            data={periodAnalysisFormattedData('previous')}
            filename={filenameDate(previousPeriodDateFilter)}
            buttonName={'Export previous period'}
          />
        </div>
      )
    }
  }

  const renderDataTable = () => {

    const portfolioBenchmarkTableData = () => {
      const data = portfolioBenchmarkData?.portfolioBenchmark
      const formattedData = data?.dates.map((date, i) => ({
        'date': moment.utc(date).format('YYYY-MM-DD HH:mm:ss'),
        ...(isEdgeDataPro ? {} : {['Your portfolio']: data.yourPortfolio[i]}),
        'Average edgepetrol company': data.averageEdgepetrolCompany[i],
      }))

      return formattedData && formattedData[0]
        ? Object.entries(formattedData[0]).map(([key]) => {
            if (key !== 'date') {
              return formattedData.map(item => ({
                date: item.date,
                value: item[key] === null ? null : parseFloat(item[key]),
              }))
            }
            return null
          }).filter(Boolean) : []
    }

    const competitorAnalysisTableData = () => {
      const data = competitorAnalysisData?.competitorAnalysis
      const formattedData = data?.dates.map((date, i) => ({
        'date': moment.utc(date).format('YYYY-MM-DD HH:mm:ss'),
        'Your average pole price': data.yourAveragePolePrice[i],
        'Average competitor pole price': data.averageCompetitorPolePrice[i],
        'Min competitor pole price': data.minCompetitorPolePrice[i],
        'Max competitor pole price': data.maxCompetitorPolePrice[i],
      }))

      return formattedData && formattedData[0]
        ? Object.entries(formattedData[0]).map(([key]) => {
            if (key !== 'date') {
              return formattedData.map(item => ({
                date: item.date,
                value: item[key] === null ? null : parseFloat(item[key]),
              }))
            }
            return null
          }).filter(Boolean) : []
    }

    const periodAnalysisTableData = () => {
      const allPeriodSites = periodAnalysisData?.periodAnalysis.data;
    
      if (!allPeriodSites) {
        return [];
      }
    
      const halfLength = allPeriodSites.length / 2;
      const currentDates =  periodAnalysisData?.periodAnalysis.datesCurrentPeriod
      const previousDates = periodAnalysisData?.periodAnalysis.datesPreviousPeriod
      const dates = currentDates.length > previousDates.length ? currentDates : previousDates;

      return allPeriodSites.slice(0, halfLength).map((site, j) => {
        const formattedData = dates.map((date, i) => ({
          'date': moment.utc(currentDates[i])?.format('YYYY-MM-DD HH:mm:ss'),
          'value': parseFloat(site.values[i]),
          'previousValue': parseFloat(allPeriodSites[j + halfLength]?.values[i]),
          'previousDate': moment.utc(previousDates[i])?.format('YYYY-MM-DD HH:mm:ss')
        }));
    
        return formattedData;
      });
    }
  
    const periodAnalysisLabel = () => {
      const allPeriodSites = periodAnalysisData?.periodAnalysis.data
    
      if (!allPeriodSites) {
        return []
      }
    
      const halfLength = allPeriodSites.length / 2
    
      return allPeriodSites.slice(0, halfLength).map((site) => {
        const label = site.label.split(" - ")[0]
        return label
      })
    }
  
    const portfolioBenchmarkHeaders = [
      ...(isEdgeDataPro ? [] : ['Your portfolio']),
      'Average EdgePetrol site'
    ]
    const competitorAnalysisHeaders = ['Your average pole price', 'Average competitor pole price', 'Min competitor pole price', 'Max competitor pole price']

    const tableData = portfolioBenchmarkTableData()
    const periodAnalysisHeaders = periodAnalysisLabel()
    const periodAnalysisDataTable = periodAnalysisTableData()
    const competitorAnalysisDataTable = competitorAnalysisTableData()

    if (activeTab === PortfolioBenchmark && tableData.length) {
      return (
        <DataTable
          data={tableData}
          metrics={portfolioBenchmarkHeaders}
          period1={currentPeriodDateFilter[activeTab]}
          metricType={metricType}
          highlight={null}
          previousColumn={false}
          analytics={true}
        />
      )
    }

    if (activeTab === CompetitorAnalysis && competitorAnalysisDataTable.length) {
      return (
        <DataTable
          data={competitorAnalysisDataTable}
          metrics={competitorAnalysisHeaders}
          period1={currentPeriodDateFilter[activeTab]}
          metricType={metricType}
          highlight={null}
          previousColumn={false}
          analytics={true}
        />
      )
    }
  
    if (activeTab === PeriodAnalysis && periodAnalysisDataTable.length) {
      return (
        <DataTable
          data={periodAnalysisDataTable}
          metrics={periodAnalysisHeaders}
          period1={currentPeriodDateFilter[activeTab]}
          period2={previousPeriodDateFilter[activeTab]}
          metricType={metricType}
          highlight={null}
          previousColumn={true}
          analytics={true}
        />
      )
    }
  
    return null
  }
  
  return (
    <div>
      { !isEdgeDataPro && <TabNavs activeTab={activeTab} setActiveTab={setSelectedActiveTab} mainData={mainData} /> }
      <div className={cx('container')}>
        {activeTab != CompetitorAnalysis && <Metrics activeTab={activeTab} />}
        <Filters
          activeTab={activeTab}
          stationIds={stationIds[activeTab]}
          setSelectedStation={setSelectedStation}
          fuelGradesItems={allFuelGrades?.map((fg) => fg.title)}
          fuelGrades={fuelGrades[activeTab]}
          setSelectedGrades={setSelectedGrades}
          isEdgeDataPro={isEdgeDataPro}
        />
        { renderChart() }
        { renderExportButton() }
        { renderDataTable() }
      </div>
    </div>
  )
}

AnalyticsReport.propTypes = {
  mainData: PropTypes.shape(),
}

const FallbackComponent = () => {
  const { t } = useTranslation(['translations'])

  return (
    <WidgetFallback
      title={upperFirstOnly(t('widgets.analytics.title'))}
    />
  )
}

const AnalyticsReportWithErrorBoundary = withEdgeErrorBoundary(
  AnalyticsReport,
  FallbackComponent
)

export { AnalyticsReportWithErrorBoundary as AnalyticsReport }
